// 创建整个应用的路由器
import VueRouter from 'vue-router'

//创建并暴露一个路由器：from从哪来，to到哪里去
const router = new VueRouter({
	mode: 'hash',//工作模式history，没有#，但是有时候他会把参数当成访问地址
	//hash:#自动区分路径参数与访问路径，有#
	routes: [
		{
			name: "login",
			path: '/',
			// 第二种引入方式:注意单引号使用''，=>不能同时匹配多个组件***
			component: () => import('../components/Login.vue'),
		},
		{
			name: "home",
			path: '/home',
			// 第二种引入方式:注意单引号使用''，=>不能同时匹配多个组件***
			component: () => import('../components/Home.vue'),
			children: [{
				name: "book",
				path: '/book',
				component: () => import('../components/Book.vue'),
			}, {
				name: "user",
				path: '/user',
				component: () => import('../components/User.vue'),
			},{
				name: "allOrders",
				path: '/allOrders',
				component: () => import('../components/AllOrders.vue'),
			},{
				name: "announcement",
				path: '/announcement',
				component: () => import('../components/Announcement.vue'),
			}]
		},
		{
			name:"goods",
			path:"/goods",
			component: () => import('../components/Goods.vue'),
			children:[{
				// 详细商品展示
				name:"details",
				path:"/details",
				component: () => import('../components/Details.vue'),
			},{
				//订单管理
				name:"orders",
				path:"/orders",
				component: () => import('../components/Orders.vue'),
			},{
				name:"address",
				path:"/address",
				component: () => import('../components/Address.vue'),
			}]
		}
	]
})

router.beforeEach((to, from, next) => {
	if (to.path === "/") return next()
	else {
		if (localStorage.getItem('userMsg')) {
			return next()
		} else {
			return next({ name: "login" })
		}
	}

})
export default router