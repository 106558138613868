import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'//引入路由插件
//引入路由器：自动配置index.js
import router from './router'
import ElementUI from 'element-ui'
import "element-ui/lib/theme-chalk/index.css"

Vue.use(ElementUI)
//关闭Vue的生产提示
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')

var url="http://localhost:8023"

export default url
