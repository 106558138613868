<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import autofit from "autofit.js"
export default {
  name: 'App',
  components: {
    
  },
  mounted(){
    //配置autofit.js，全屏响应式布局
    // autofit.init({
    //   dh:500,
    //   dw:1300,
    //   el:"#app",
    //   resize:true
    // })
  }
}
</script>
<style scoped>
#app{
  height: 100%;
}
</style>

